/*
 * @Author: 顾喜斌 xibin_gu@163.com
 * @Date: 2023-01-17 14:15:00
 * @LastEditors: 顾喜斌 xibin_gu@163.com
 * @LastEditTime: 2023-02-24 12:15:12
 */

const router_modular = [
    {
        path: '/notWeixin',
        name: 'notWeixin',
        component: () => import('@/views/NotWeixin'),
        meta: {
            title: '非微信内部浏览器'
        }
    },
    {
        path: '/notFollowed',
        name: 'notFollowed',
        component: () => import('@/views/notFollowed/NotFollowed'),
        meta: {
            title: '未关注公众号'
        }
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('@/views/login/LoginView'),
        meta: {
            title: '登录'
        }
    },
    {
        path: '/register',
        name: 'register',
        component: () => import('@/views/login/RegisterView'),
        meta: {
            title: '注册'
        }
    },
    {
        path: '/loginSuccess',
        name: 'loginSuccess',
        component: () => import('@/views/login/LoginSuccess'),
        meta: {
            title: '登录成功'
        }
    },
    {
        path: '/registerSuccess',
        name: 'registerSuccess',
        component: () => import('@/views/login/RegisterSuccess'),
        meta: {
            title: '注册成功'
        }
    },
    {
        path: '/resetPwd',
        name: 'resetPwd',
        component: () => import('@/views/resetPwd/ResetPwd'),
        meta: {
            title: '重置密码',
            needToken: true
        }
    },
    {
        path: '/bindPallet',
        name: 'bindPallet',
        component: () => import('@/views/bindPallet/BindIndex'),
        meta: {
            title: '绑定托盘'
        }
    },
    {
        path: '/tray',
        name: 'tray',
        component: () => import('@/views/bindPallet/BindTray'),
        meta: {
            title: '绑定托盘'
        }
    },
    {
        path: '/userCenter',
        name: 'userCenter',
        component: () => import('@/views/userCenter/UserCenter'),
        meta: {
            title: '个人中心'
        }
    },
    {
        path: '/account',
        name: 'account',
        component: () => import('@/views/userCenter/AccountView'),
        meta: {
            title: '账号设置'
        }
    },
    {
        path: '/modifyName',
        name: 'modifyName',
        component: () => import('@/views/userCenter/ModifyName'),
        meta: {
            title: '修改昵称'
        }
    },
    {
        path: '/cashOut',
        name: 'cashOut',
        component: () => import('@/views/userCenter/CashOut'),
        meta: {
            title: '提现'
        }
    },
    {
        path: '/paymentCode',
        name: 'paymentCode',
        component: () => import('@/views/userCenter/PaymentCode'),
        meta: {
            title: '付款码'
        }
    },
    {
        path: '/recharge',
        name: 'recharge',
        component: () => import('@/views/userCenter/RechargeView'),
        meta: {
            title: '充值'
        }
    },
    {
        path: '/rechargeSuccess',
        name: 'rechargeSuccess',
        component: () => import('@/views/userCenter/RechargeSuccess'),
        meta: {
            title: '充值成功'
        }
    },
    {
        path: '/order',
        name: 'order',
        component: () => import('@/views/userCenter/BillView'),
        meta: {
            title: '账单',
            keepAlive: true
        }
    },
    {
        path: '/orderDetail',
        name: 'orderDetail',
        component: () => import('@/views/userCenter/BillDetail'),
        meta: {
            title: '账单详情',
            keepAlive: true
        }
    },
    {
        path: '/nutritionalAnalysis',
        name: 'nutritionalAnalysis',
        component: () => import('@/views/userCenter/NutritionalAnalysis'),
        meta: {
            title: '营养分析',
            keepAlive: true
        }
    },
    {
        path: '/nutritionalMeal',
        name: 'nutritionalMeal',
        component: () => import('@/views/userCenter/NutritionalMeal'),
        meta: {
            title: '营养分析'
        }
    },
    {
        path: '/mealReport',
        name: 'mealReport',
        component: () => import('@/views/userCenter/MealReport'),
        meta: {
            title: '报餐',
            keepAlive: true
        }
    },
    {
        path: '/mealReportQuestionnaire',
        name: 'mealReportQuestionnaire',
        component: () => import('@/views/userCenter/MealReportQuestionnaire'),
        meta: {
            title: ''
        }
    },
    {
        path: '/feedback',
        name: 'feedback',
        component: () => import('@/views/userCenter/FeedbackView'),
        meta: {
            title: '建议与反馈',
            keepAlive: true
        }
    },
    {
        path: '/feedbackDetail',
        name: 'feedbackDetail',
        component: () => import('@/views/userCenter/FeedbackDetail'),
        meta: {
            title: '反馈详情'
        }
    },
    {
        path: '/bindCardModule',
        name: 'bindCardModule',
        component: () => import('@/views/userCenter/BindCardModule'),
        meta: {
            title: '绑卡'
        }
    },
    {
        path: '/foodMenu',
        name: 'foodMenu',
        component: () => import('@/views/userCenter/FoodMenuView'),
        meta: {
            title: '一周菜单'
        }
    },
    {
        path: '/changePassWord',
        name: 'changePassWord',
        component: () => import('@/views/userCenter/changePassWord'),
        meta: {
            title: '修改密码'
        }
    },
    {
        path: '/preMeals',
        name: 'preMeals',
        component: () => import('@/views/userCenter/preMealsView'),
        meta: {
            title: '预订点餐'
        }
    },
    {
        path: '/confirmOrder',
        name: 'confirmOrder',
        component: () => import('@/views/userCenter/confirmOrder'),
        meta: {
            title: '确认订单信息'
        }
    },
    {
        path: '/confirmSuccess',
        name: 'confirmSuccess',
        component: () => import('@/views/userCenter/confirmSuccess'),
        meta: {
            title: '支付结果页'
        }
    },
    // 汉阳点餐
    {
        path: '/hyPreMeals',
        name: 'hyPreMeals',
        component: () => import('@/views/hanyang/hyPreMealsView'),
        meta: {
            title: '点餐'
        }
    },
    // 汉阳确认订单信息
    {
        path: '/hyConfirmOrder',
        name: 'hyConfirmOrder',
        component: () => import('@/views/hanyang/hyConfirmOrder'),
        meta: {
            title: '确认订单信息'
        }
    },
    // 汉阳支付结果页面
    {
        path: '/hyConfirmSuccess',
        name: 'hyConfirmSuccess',
        component: () => import('@/views/hanyang/hyConfirmSuccess'),
        meta: {
            title: '支付结果页'
        }
    },
    {
        path: '/foodTaboos',
        name: 'foodTaboos',
        component: () => import('@/views/foodTaboos/foodTaboosView'),
        meta: {
            title: '饮食禁忌'
        }
    },
    {
        path: '/about',
        name: 'about',
        component: () => import('@/views/aboutUs/AboutView'),
        meta: {
            title: '了解我们',
            isInWhileList: true  // 白名单 不校验token
        }
    },
    {
        path: '/middleJumpPage',
        name: 'middleJumpPage',
        component: () => import('@/views/middlePage/middleJumpView'),
        meta: {
            title: '正在跳转',
            isInWhileList: true  // 白名单 不校验token
        }
    },
    {
        path: '/payNotWeixin',
        name: 'payNotWeixin',
        component: () => import('@/views/middlePage/payNotWeixin'),
        meta: {
            title: '非微信内部浏览器',
            isSpecialPage: true     // 特殊页 与用户无关
        }
    },
    {
        path: '/paymentPage',
        name: 'paymentPage',
        component: () => import('@/views/middlePage/paymentView'),
        meta: {
            title: '支付',
            isSpecialPage: true     // 特殊页 与用户无关
        }
    },
    {
        path: '/notTokenPage',
        name: 'notTokenPage',
        component: () => import('@/views/notTokenPage/NotTokenPage'),
        meta: {
            title: '自动登录中'
        }
    },
    {
        path: '/openMiniWechat',
        name: 'openMiniWechat',
        component: () => import('@/views/openMiniWechat/openMiniWechat'),
        meta: {
            title: 'open小程序'
        }
    }
]
export default router_modular